import thumb1 from '../../src/images/data/My_Sky_VIP_logo.png';
import thumb2 from '../../src/images/data/My_Sky_Store_logo.png';
import thumb3 from '../../src/images/data/My_Sky_News_logo.png';
import thumb4 from '../../src/images/data/My_Sky_SPORT_logo.png';
import thumb5 from '../../src/images/data/My_Sky_KIDS_logo.png';

import img1 from '../../src/images/data/My_Sky_homepage_Retail_App_Assets_IT_.png';

import logo from '../../src/assets/logo.jpg';

const tv = {
    data : {
        wp: {
            home: {
                dataHeader : {
                    home:true, 
                    logo: logo,
                    back:true
                },
                title : 'Select a category',
                options : [
                    {
                        id: 'tv-glass',
                        title : 'My Sky',
                        thumb : thumb1,
                        img : img1,
                        link: '/apps/my-sky', 
                    },
                    {
                        id: 'tv-q',
                        title : 'Sky Go',
                        thumb : thumb2,
                        img : img1,
                        link: '/apps/sky-go'
                    },
                    {
                        id: 'sky-News',
                        title : 'Sky News',
                        thumb : thumb3,
                        img : img1,
                        link: '/apps/sky-news'
                    },
                    {
                        id: 'sky-Sports',
                        title : 'Sky Sports',
                        thumb : thumb4,
                        img : img1,
                        link: '/apps/sky-sports'
                    },
                    {
                        id: 'sky-kids',
                        title : 'Sky Kids',
                        thumb : thumb5,
                        img : img1,
                        link: '/apps/sky-kids'
                    },
                ]
            }
        },

    }
}

export default tv;